import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./components/Home";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
     {
          path: "/",
          element: <app></app>,
          children: [
               {
                    path: "/",
                    element: <home></home>,
               },
          ],
     },
]);

const root = ReactDOM.createRoot(document.getElementById("root"))；
root.render(
     <react.strictmode>
          {/* <app></app> */}
          <routerprovider router="{router}"></routerprovider>
     </react.strictmode>
);

// 如果您想在应用程序中开始测量性能，请传递一个函数来记录结果（例如：reportWebVitals(console.log)
// 例如：reportWebVitals(console.log))
// 或发送到分析端点。了解更多信息： https://bit.ly/CRA-vitals
reportWebVitals()；
